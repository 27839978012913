var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3 text-center h4"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"squared":"","variant":"success","block":""},on:{"click":_vm.handlerYedekleme}},[_c('i',{staticClass:"fad fa-download"}),_vm._v(" Yedekleme Al ")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{staticClass:"pa-2",attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"pagination-options":{
          enabled: true,
          perPage: 15,
          position: 'bottom',
          perPageDropdown: [5, 15, 20, 30, 50, 70, 100],
          dropdownAllowAll: true,
          firstLabel: 'İlk Sayfa',
          lastLabel: 'Son Sayfa',
          nextLabel: 'İleri',
          prevLabel: 'Geri',
          rowsPerPageLabel: 'Sayfa Başı Satır Sayısı',
          ofLabel: 've',
          pageLabel: 'Sayfa', // for 'pages' mode
          allLabel: 'Tümü',
        },"search-options":{
          enabled: true,
          trigger: 'enter',
          placeholder: 'Açıklamaya Göre Ara...',
        },"isLoading":_vm.isLoading,"totalRows":_vm.count,"sort-options":{
          enabled: false,
        },"styleClass":"vgt-table striped bordered condensed"},on:{"search":_vm.onSearchFilter,"page-change":_vm.onPageChange,"per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"emptystate",fn:function(){return [_c('h4',{staticClass:"text-center py-3"},[_vm._v("Gösterilecek kayıt yoktur !")])]},proxy:true},{key:"table-row",fn:function(props){return [(props.column.field === 'tarih')?_c('span',[_vm._v(" "+_vm._s(_vm._f("momentFull")(props.row.tarih)))]):(props.column.field === 'actions')?_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Geri Yükle'),expression:"'Geri Yükle'",modifiers:{"hover":true,"bottom":true}}],attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.handlerRestore(props.row)}}},[_c('i',{staticClass:"fad fa-upload"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Sil'),expression:"'Sil'",modifiers:{"hover":true,"bottom":true}}],attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.handlerRemove(props.row)}}},[_c('i',{staticClass:"fad fa-trash"})])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }