<template>
  <div class="p-3 text-center h4">
    <b-row>
      <b-col cols="12" md="6">
        <b-button squared variant="success" block @click="handlerYedekleme"> <i class="fad fa-download"></i> Yedekleme Al </b-button>
      </b-col>
      <b-col cols="12">
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          class="pa-2"
          :pagination-options="{
            enabled: true,
            perPage: 15,
            position: 'bottom',
            perPageDropdown: [5, 15, 20, 30, 50, 70, 100],
            dropdownAllowAll: true,
            firstLabel: 'İlk Sayfa',
            lastLabel: 'Son Sayfa',
            nextLabel: 'İleri',
            prevLabel: 'Geri',
            rowsPerPageLabel: 'Sayfa Başı Satır Sayısı',
            ofLabel: 've',
            pageLabel: 'Sayfa', // for 'pages' mode
            allLabel: 'Tümü',
          }"
          :search-options="{
            enabled: true,
            trigger: 'enter',
            placeholder: 'Açıklamaya Göre Ara...',
          }"
          v-on:search="onSearchFilter"
          v-on:page-change="onPageChange"
          v-on:per-page-change="onPerPageChange"
          :isLoading="isLoading"
          :totalRows="count"
          :sort-options="{
            enabled: false,
          }"
          styleClass="vgt-table striped bordered condensed"
        >
          <template #emptystate> <h4 class="text-center py-3">Gösterilecek kayıt yoktur !</h4> </template>

          <template #table-row="props">
            <span v-if="props.column.field === 'tarih'"> {{ props.row.tarih | momentFull }}</span>
            <span v-else-if="props.column.field === 'actions'">
              <b-button size="sm" variant="primary" @click="handlerRestore(props.row)" v-b-tooltip.hover.bottom="'Geri Yükle'">
                <i class="fad fa-upload"></i>
              </b-button>
              <b-button size="sm" variant="danger" @click="handlerRemove(props.row)" v-b-tooltip.hover.bottom="'Sil'">
                <i class="fad fa-trash"></i>
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import axiosIns from '@/libs/axios';
import { VueGoodTable } from 'vue-good-table';
import Swal from 'sweetalert2';
export default defineComponent({
  components: {
    VueGoodTable,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);

    expo.columns = ref([
      {
        label: 'Tarih',
        field: 'tarih',
      },
      {
        label: 'Açıklama',
        field: 'aciklama',
      },
      {
        label: 'İşlemler',
        field: 'actions',
        thClass: 'text-right',
        tdClass: 'text-right',
        width: '10%',
      },
    ]);

    expo.isLoading = ref(false);
    expo.rows = ref([]);
    expo.count = ref(0);
    const filtered = ref({
      search: null,
      page: 1,
      page_size: 15,
    });

    const handlerFetchAllData = async () => {
      await axiosIns.get('/ayarlar/yedekleme-listele-get', { params: filtered.value }).then((res) => {
        if (res.data.success) {
          expo.rows.value = res.data.data;
          expo.count.value = res.data.count;
        }
      });
    };

    handlerFetchAllData();

    expo.handlerYedekleme = async () => {
      await axiosIns.post('/ayarlar/yedekleme-backup-post').then((res) => {
        if (res.data.success) {
          expo.rows.value.push(res.data.data);
          toast.success('Yedekleme alme işlemi tamalanmıştır.');
        }
      });
    };

    expo.handlerRestore = async (payload) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Bu kayıttan sonra yapılan tüm değişiklikler silinecek ve bu yedekteki veriler işlenecektir. Eminmisiniz !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then(async (result) => {
        if (result.isConfirmed == true) {
          await axiosIns.post('/ayarlar/yedekleme-restore-post', payload).then((res) => {
            if (res.data.success) {
              toast.success('Yedekleme geri yükleme tamamlanmıştır.');
            }
          });
        }
      });
    };

    expo.handlerRemove = (payload) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Bu Kayıt Silinecektir Eminmisiniz !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then(async (result) => {
        if (result.isConfirmed == true) {
          await axiosIns.post('/ayarlar/yedekleme-sil-post', payload).then((res) => {
            if (res.data.success) {
              const index = expo.rows.value.findIndex((x) => x.k_no == payload.k_no);
              expo.rows.value.splice(index, 1);
              toast.error('Yedekleme silinmiştir.');
            }
          });
        }
      });
    };

    expo.onSearchFilter = (event) => {
      filtered.value.search = event.searchTerm;
      handlerFetchAllData();
    };
    expo.onPageChange = (event) => {
      filtered.value.page = event.currentPage;
      handlerFetchAllData();
    };
    expo.onPerPageChange = (event) => {
      filtered.value.page_size = event.currentPerPage;
      handlerFetchAllData();
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
